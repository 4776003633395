





































































































































































import { saveAs } from 'file-saver';
import { api } from '@/api';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Dashboard extends Vue {
  public file: any = null;
  public fileType: any = null;
  public loading: boolean = false;
  public tableLoading: boolean = false;
  public timer: any = null;
  public timerStarted: boolean = false;
  public stopTaskLoading: boolean = false;
  public downloadLoading: boolean = false;
  public tasks: any[] = [];
  public deleteTaskLoading: boolean = false;
  public expanded: any[] = [];

  public async uploadFile() {
    try {
      this.loading = true;
      const formData = new FormData();
      formData.append('input_file', this.file);
      formData.append('file_type', this.fileType);
      await api.uploadFile(this.$store.getters.token, formData);
      this.file = null;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
      await this.loadData();
    }
  }

  public startTimer() {
    this.timerStarted = true;
    this.loadData();
    this.timer = setTimeout(this.loadByTimer, 3000);
  }

  public loadByTimer() {
    this.loadData(() => {
      if (this.timerStarted) { this.startTimer(); }
    });
  }

  public stopTimer() {
    if (this.timer) { clearTimeout(this.timer); }
    this.timer = null;
    this.timerStarted = false;
  }

  public async beforeDestroy() {
    this.stopTimer();
  }

  public async loadData(callback = () => { /* empty */ }) {
    try {
      this.tableLoading = true;

      const { data } = await api.loadTasks(this.$store.getters.token);
      this.tasks = data as any[];
      console.log("tasks", this.tasks);
    } catch (e) {
      console.error(e);
    } finally {
      this.tableLoading = false;
      callback();
    }
  }

  public async stopTask(item: any) {
    try {
      this.stopTaskLoading = true;
      await api.cancelTask(this.$store.getters.token, item.id);
    } catch (e) {
      console.error();
    } finally {
      this.stopTaskLoading = false;
      await this.loadData();
    }
  }

  public async deleteTask(item: any) {
    try {
      this.deleteTaskLoading = true;
      await api.deleteTask(this.$store.getters.token, item.id);
    } catch (e) {
      console.error();
    } finally {
      this.deleteTaskLoading = false;
      await this.loadData();
    }
  }

  public async downloadTaskResult(id: number, name: string) {
    try {
      this.downloadLoading = true;

      const { data, headers } = await api.downloadFile(this.$store.getters.token, id);
      const disposition = headers['content-disposition'];
      let filename = `${name}_${id}.xlsx`;
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename\*?=([^']*'')?([^;]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[2]) {
          filename = matches[2].replace(/['"]/g, '');
        }
      }
      saveAs(data as Blob, decodeURIComponent(filename));
    } catch (e) {
      console.error();
    } finally {
      this.downloadLoading = false;
    }
  }
  public async downloadJsonLResult(id: number, name: string) {
    try {
      this.downloadLoading = true;

      const { data, headers } = await api.downloadJsonlFile(this.$store.getters.token, id);
      const disposition = headers['content-disposition'];
      let filename = `${name}_${id}.jsonl`;
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename\*?=([^']*'')?([^;]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[2]) {
          filename = matches[2].replace(/['"]/g, '');
        }
      }
      saveAs(data as Blob, decodeURIComponent(filename));
    } catch (e) {
      console.error();
    } finally {
      this.downloadLoading = false;
    }
  }
  public async created() {
    await this.loadData();
  }
}
