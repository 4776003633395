var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Dashboard")])]),_c('v-card-text',[_c('v-file-input',{attrs:{"chips":"","counter":"","show-size":"","small-chips":"","disabled":_vm.loading,"truncate-length":"15","placeholder":"Select file..."},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('v-select',{attrs:{"label":"Select","items":['Default', 'Super Admin', 'Fall', 'Lease Renewal', 'Move In', 'Move Out', 'Prospect-expectations', 'Prospect-overall', 'Resident', 'Spring', 'Workorder'],"chips":"","counter":"","show-size":"","small-chips":"","disabled":_vm.loading},model:{value:(_vm.fileType),callback:function ($$v) {_vm.fileType=$$v},expression:"fileType"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","elevation":"2","disabled":_vm.loading || !_vm.file,"loading":_vm.loading},on:{"click":_vm.uploadFile}},[_vm._v("Upload")])],1)],1),_c('div',{staticClass:"ma-3 pa-0"},[(!_vm.timerStarted)?_c('v-btn',{attrs:{"color":"success","elevation":"2","disabled":_vm.tableLoading},on:{"click":function($event){return _vm.startTimer()}}},[_vm._v("Start realtime update")]):_c('v-btn',{attrs:{"color":"error","elevation":"2","disabled":_vm.tableLoading},on:{"click":function($event){return _vm.stopTimer()}}},[_vm._v("Stop realtime update")])],1),_c('v-card',{staticClass:"ma-3 pa-0",attrs:{"loading":_vm.downloadLoading || _vm.stopTaskLoading || _vm.deleteTaskLoading}},[_c('v-data-table',{staticClass:"elevation-0",style:({ width: '100%' }),attrs:{"calculate-widths":"","loading":_vm.tableLoading,"show-expand":"","single-expand":"","expanded":_vm.expanded,"options":{ sortBy: ['id'], sortDesc: [true] },"headers":[
        { text: '#', value: 'id', width: 100 },
        { text: 'Input File', value: 'input_file_name' },
        { text: 'Status', value: 'status', width: 200 },
        { text: 'Error', value: 'data-table-expand', width: 60, sortable: false },
        { text: 'Download report', value: 'report_file_id', width: 130, sortable: false },
        { text: 'Download sentiment report', value: 'sentiment_report_file_id', width: 180, sortable: false },
        { text: 'Download JSONL', value: 'jsonl_report_file_id', width: 130, sortable: false },
        // { text: 'Error', value: 'error_msg', width: 200, sortable: false },
        { text: 'Actions', value: 'actions', width: 100, sortable: false, align: 'end' } ],"items":_vm.tasks,"items-per-page":15},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{style:({ padding: 0 }),attrs:{"colspan":headers.length}},[_c('v-alert',{style:({ borderRadius: 0, margin: 0 }),attrs:{"type":"error","elevation":0}},[_vm._v(_vm._s(item.error_msg))])],1)]}},{key:"item.data-table-expand",fn:function(ref){
      var item = ref.item;
      var isExpanded = ref.isExpanded;
      var expand = ref.expand;
return [(item.error_msg)?_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(" "+_vm._s(!isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up')+" ")]):_vm._e()]}},{key:"item.input_file_name",fn:function(ref){
      var item = ref.item;
return _c('div',{style:({ minWidth: '300px' })},[_vm._v(" "+_vm._s(item.input_file_name)+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.downloadTaskResult(item.input_file_id, item.input_file_name)}}},[_vm._v(" mdi-download ")])],1)}},{key:"item.report_file_id",fn:function(ref){
      var item = ref.item;
return _c('v-btn',{attrs:{"x-small":"","color":"primary","disabled":!item.report_file_id},on:{"click":function($event){return _vm.downloadTaskResult(item.report_file_id, 'report')}}},[_vm._v("Download")])}},{key:"item.sentiment_report_file_id",fn:function(ref){
      var item = ref.item;
return _c('v-btn',{attrs:{"x-small":"","color":"primary","disabled":!item.sentiment_report_file_id},on:{"click":function($event){return _vm.downloadTaskResult(item.sentiment_report_file_id, 'sentiment_report')}}},[_vm._v("Download")])}},{key:"item.jsonl_report_file_id",fn:function(ref){
      var item = ref.item;
return _c('v-btn',{attrs:{"x-small":"","color":"primary","disabled":item.status !== 'completed'},on:{"click":function($event){return _vm.downloadJsonLResult(item.id, 'jsonl_report')}}},[_vm._v("Download")])}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"status-field"},[_c('div',[_c('v-chip',{staticClass:"text-uppercase",attrs:{"color":item.status === 'running' ? 'primary' : item.status === 'failed' ? 'error' : item.status === 'completed' ? 'success' : undefined,"x-small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])],1),_c('div',[(['pending', 'running'].includes(item.status))?_c('v-btn',{attrs:{"x-small":"","disabled":_vm.stopTaskLoading,"color":"error"},on:{"click":function($event){return _vm.stopTask(item)}}},[_vm._v("Cancel")]):_vm._e()],1)])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","disabled":_vm.deleteTaskLoading,"color":"error"},on:{"click":function($event){return _vm.deleteTask(item)}}},[_vm._v("Delete")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }